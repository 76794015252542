.mesa-de-regalo {
  width: 100%;
  padding: 20px; /* Reduced padding for better fit on mobile */
  background-color: #f9f9f9;
  text-align: center;
  box-sizing: border-box; /* Ensures padding doesn't cause overflow */
}

.mesa-de-regalo h2 {
  color: #574e49;
  font-size: 1.8rem; /* Slightly reduced font size for better readability on mobile */
  margin-bottom: 15px; /* Adjusted margin for better spacing on mobile */
  font-family: 'Seri', sans-serif;
}

.mesa-de-regalo p {
  color: #495057;
  font-family: 'Seri', sans-serif;
  font-size: 2rem;
  margin-bottom: 40px; /* Adjusted margin for better spacing on mobile */
  padding-top: 30px;
}

.store-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Add gap between items to prevent them from being too close */
}

@media (max-width: 600px) {
  .mesa-de-regalo {
    padding: 15px; /* Further reduced padding on small screens */
  }

  .mesa-de-regalo h2 {
    color: #574e49;
    font-size: 1.8rem; /* Slightly reduced font size for better readability on mobile */
    margin-bottom: 15px;
    font-family: 'Seri', sans-serif;
  }

  .mesa-de-regalo p {
    font-size: 1rem;
  }
}

@font-face {
  font-family: 'Seri';
  src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Codec';
  src: url('../../assets/fonts/Codec-Warm-Light-trial.ttf') format('truetype');
}