.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-auto-rows: 350px; /* Set the base height of each row */
  gap: 20px; /* Ensure consistent spacing between images */
  padding: 20px;
  background-color: #495057;
  height: 100%;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Span the first image across two columns and two rows */
.image-item.span-large {
  grid-column: span 2;
  grid-row: span 1.5;
}

/* Responsive design for mobile (less than 600px wide) */
@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 4 columns */
    grid-auto-rows: auto;
  }

  .image-item.span-large {
    grid-column: auto;
    grid-row: auto; /* Remove spans for mobile */
  }

}
