.presentacion {
  background-color: #f8f9fa;
  margin-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 40px;
  height: auto; /* Adjusted height to fit content */
  color: black;
  text-align: center;
}

.presentacion h4 {
  margin: 10px 0; /* Add spacing around each heading */
  font-family: Arial, sans-serif;
}

.presentacion .line {
  width: 60%;
  height: 4px; /* Adjust the thickness of the line */
  background-color: black;
  margin: 20px auto; /* Center the line within the container */
}

/* Parent container to hold both the left and right sections */
.presentacion .parents-container {
  display: flex;
  justify-content: space-between; /* Spread the child elements to left and right */
  align-items: center; /* Vertically center-align the items */
  width: 100%; /* Take the full width of the container */
  margin: 20px 0; /* Spacing between the text and the line */
}

/* Left parents container */
.parents-container .papas_s {
  width: 45%; /* Left container takes 45% width */
  text-align: left; /* Align text to the left */
}

/* Right parents container */
.parents-container .papas_m {
  width: 45%; /* Right container takes 45% width */
  text-align: right; /* Align text to the right */
}

/* Responsive Design */
@media (max-width: 600px) {
  .presentacion .parents-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .presentacion .papas_s {
    width: 100%; /* Full width for both sections */
    text-align: right; /* Center-align text */
    margin-bottom: 15px; /* Add some spacing */
  }
  .presentacion .papas_m {
    width: 100%; /* Full width for both sections */
    text-align: left; /* Center-align text */
    margin-bottom: 15px; /* Add some spacing */
  }

  .parents-container h4 {
    font-size: medium;
  }
}

.parents-container h4 {
  width: auto;
  font-family: 'Seri', sans-serif;;
}



  @font-face {
    font-family: 'Seri';
    src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Codec';
    src: url('../../assets/fonts/Codec-Warm-Light-trial.ttf') format('truetype');
  }

  .titulo_p{
    font-family: 'Brown Sugar', sans-serif; /* Use the Brown Sugar font */
    font-size: 3rem;
    margin: 0;
    padding: 0;
    color: black;
    margin-bottom: 40px;
  }
  
  .fecha-container {
    text-align: center;
  }

  #mensaje{
    font-family: 'Seri', sans-serif;;
    margin-bottom: 40px;
  }
  
  .linea {
    display: inline-block;
    height: 25px; /* Ajusta la altura de la línea */
    width: 1.8px; /* Ajusta el ancho de la línea */
    background-color: black; /* Ajusta el color de la línea */
    margin: 0 10px; /* Ajusta el margen entre las líneas y el texto */
  }
  
  span {
    font-size: 22px; /* Ajusta el tamaño de la fuente */
    font-weight: bold;
    font-family: 'Brown Sugar', sans-serif;
  }
  
  .numero {
    font-size: 27px; /* Ajusta el tamaño de la fuente */
    font-weight: bold;
    font-family: 'Brown Sugar', sans-serif;
  }