.container {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 5%;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  width: 100%;
  max-width: 60%; /* Ensure it fills the entire width */
  margin: 0 auto; /* Center the container within its parent */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
  height: 500px;
}

.image-container {
  flex-shrink: 0;
  margin-right: 20px;
  margin-left: 60px;
  margin-top: 20px;
}

.church-icon {
  width: 150px; /* Reduced size for better fit */
  height: 150px; /* Reduced size for better fit */
}

.text-container {
  flex-grow: 1;
  min-width: 0; /* Ensures the text container shrinks appropriately */
  word-wrap: break-word; /* Ensures text wraps within the container */
}


.header {
  color: #343a40;
  margin-top: 0;
  font-family: 'Seri', sans-serif;
}

.detail {
  font-size: 1.2rem;
  color: #495057;
  font-family: 'Seri', sans-serif;
}

.text-container {
  width: 95%;
  height: 200px;
  background-color: #f8f9fa;
  position: relative;
  text-align-last: right;
  padding-right: 8%;
}

.text-container::after {
  content: '';
  position: absolute;
  right: 0;
  top:0;
  bottom: 0;
  margin-right: 5%;
  height: 230px;
  width: 4px; /* Grosor de la línea */
  background-color: black; /* Color de la línea */
}


/* Responsive Design */
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    text-align: center;
    padding: 15px; /* Adjust padding for smaller screens */
    max-width: 100%;
  }

  .image-container {
    margin-bottom: 15px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .church-icon {
    width: 120px; /* Further reduced size for small screens */
    height: 120px;
  }

  .text-container {
    width: 95%; /* Ensure the text container fills the width */
    word-wrap: break-word; /* Maintain text wrapping */
  }
}

 h2 {
  color: #666;
  font-size: 1.8rem; /* Slightly reduced font size for better readability on mobile */
  margin-bottom: 15px; /* Adjusted margin for better spacing on mobile */
  font-family: 'Roboto', sans-serif;
}



@font-face {
  font-family: 'Seri';
  src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-BoldCondensed.ttf') format('truetype');
}
