.store-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin: 20px;
    color: #333;
  }
  
  .store-image {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .store-link p {
    font-size: 1rem;
    margin-top: 0;
  }
  