.itinerario {
    color: #495057;
    font-family: 'Seri', sans-serif;
    text-align: center;
    font-size: 1.8rem;
}

.inicio {
    background-color: #f8f9fa;
    width: 80%;
}


@font-face {
    font-family: 'Seri';
    src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
  }

  @media (max-width: 600px) {
    .asistencia {
      background-color: #f8f9fa; /* Adjust the color as needed */
      margin-top: 150px; /* Add a margin if you want space above the line */
      margin-right: 20px;
      margin-left: 20px;
      
    }

    .inicio {
        width: 100%;
    }
  }
  