.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Seri', sans-serif;
    color: #323131;
    margin-bottom: 40px;
  }
  
  .countdown h1 {
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #000000;
    font-family: 'Seri', sans-serif;
  }

  .countdown span {
    font-family: 'Seri', sans-serif;
  }
  
  .time {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .time-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .number {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .label {
    font-size: 1.8rem;
    color: #757575;
  }
  
  /* Responsive for mobile */
  @media (max-width: 600px) {
    .countdown h1 {
      font-size: 1.8rem;
    }
  
    .number {
      font-size: 2.3rem;
    }
  
    .label {
      font-size: 1.0rem;
    }
  
    .time {
      gap: 10px;
    }
  }

  @font-face {
    font-family: 'Seri';
    src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roboto';
    src: url('../../assets/fonts/Roboto-BoldCondensed.ttf') format('truetype');
  }
  