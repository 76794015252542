.asistencia {
    background-color: #f8f9fa; /* Adjust the color as needed */
    margin-top: 40px; /* Add a margin if you want space above the line */
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 300px;
    height: 150%;

  }

  .asistencia h2 {
    color: #495057;
    font-size: 1.8rem; /* Slightly reduced font size for better readability on mobile */
    margin-bottom: 15px; /* Adjusted margin for better spacing on mobile */
    font-family: 'Seri', sans-serif;
  }

  
@media (max-width: 600px) {
  .asistencia {
    background-color: #f8f9fa; /* Adjust the color as needed */
    margin-top: 150px; /* Add a margin if you want space above the line */
    margin-right: 20px;
    margin-left: 20px;
    
  }
}

.asistencia p{
  color: #495057;
  font-family: 'Seri', sans-serif;
}

@font-face {
  font-family: 'Seri';
  src: url('../../assets/fonts/Serithai-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Codec';
  src: url('../../assets/fonts/Codec-Warm-Light-trial.ttf') format('truetype');
}

.line1 {
  width: 100%;
  height: 4px; /* Ajusta el grosor de la línea */
  background-color: black;
}

.line {
  width: 100%;
  height: 4px; /* Ajusta el grosor de la línea */
  background-color: black;
  margin-top: 80px; /* Ajusta el margen superior */
}